import { twMerge } from "tailwind-merge";
interface Props extends React.ComponentPropsWithoutRef<"label"> {
    required?: boolean;
}

export const Label = ({ children, className, required = false, ...otherProps }: Props) => {
    if (!children) return null;
    return (
        <label
            {...otherProps}
            className={twMerge(
                `text-medium text-primary md:mb-2 inline-flex gap-x-2 items-baseline`,
                className
            )}
        >
            {children}
            {required && <span className="text-secondary text-small">Required</span>}
        </label>
    );
};
