import { useState } from "react";
import { SelectInput } from "./SelectInput";
import { Combobox } from "@headlessui/react";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { useMultiSelectContext } from "./select-context";
import { SelectMobile } from "./SelectMobile";

export const MultiSelectMobile = () => {
    const [showModal, setShowModal] = useState(false);
    const { values, onChange, label } = useMultiSelectContext();
    return (
        <SelectMobile
            showModal={showModal}
            label={label}
            footerButtons={{
                primary: {
                    children: "Apply selections",
                    onClick: () => {
                        onChange?.(values);
                        setShowModal(false);
                    },
                    disabled: values.length === 0
                },
                secondary: {
                    children: "Clear all",
                    onClick: () => onChange?.([])
                }
            }}
            closeModal={() => setShowModal(false)}
            selectInput={
                <Combobox value={values}>
                    <SelectInput
                        onClick={() => setShowModal(true)}
                        open={false}
                        readOnly
                        mode="multi"
                    />
                </Combobox>
            }
        >
            <MultiSelectDropdown isMobile />
        </SelectMobile>
    );
};
