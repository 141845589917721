import React, { ReactNode, forwardRef } from "react";
import { Icon as PhosphorIconT, IconProps } from "phosphor-react";
import { ClassNameValue, twMerge } from "tailwind-merge";

import { TooltipWrapper } from "../TooltipWrapper";

export type ButtonVariantT = "primary" | "secondary" | "text";
export type ButtonColorT = "default" | "error" | "link";
export type ButtonSizeT = "small" | "medium";
export type ButtonTooltipModeT = "hover" | "click";

const defaultButtonVariant: ButtonVariantT = "primary";
const defaultButtonColor: ButtonColorT = "default";
const defaultButtonSize: ButtonSizeT = "medium";

const focusOutline =
    "focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2 focus-visible:!outline-link";

export const baseButtonStyles = `inline-flex cursor-pointer items-center justify-center rounded-xl whitespace-nowrap disabled:text-[#C8C8C8] font-medium gap-2 ${focusOutline}`;

export interface ButtonProps extends Omit<React.ComponentProps<"button">, "ref"> {
    /** Button sizing (default: medium) */
    size?: ButtonSizeT;
    /** The button style (default: primary) */
    variant?: ButtonVariantT;
    /** Button coloring (default: default) */
    color?: ButtonColorT;
    /** An icon to display to the left of the button's contents */
    PhosphorIcon?: PhosphorIconT;
    /** Any props to apply to the icon passed in */
    iconProps?: Omit<IconProps, "size">;
    /** If specified, creates a tooltip to show when the button is hovered or clicked */
    tooltip?: ReactNode;
    /** Specifies whether to show the tooltip on button hover or click (default: hover) */
    tooltipMode?: ButtonTooltipModeT;
    /** If true, shrinks the button down to just its icon when smaller than tablet size (only works with icon specified) */
    shrinkOnMobile?: boolean;
}

export const getButtonSizeStyle = (
    size: ButtonSizeT,
    isIcon = false,
    shrinkOnMobile = false
): string => {
    switch (size) {
        case "small":
            return twMerge(
                "py-1.5 text-sm h-8 leading-[8px]",
                isIcon || shrinkOnMobile ? "min-w-8" : "px-3",
                !isIcon && shrinkOnMobile && "tablet:w-fit tablet:px-3"
            );
        case "medium":
            return twMerge(
                "text-medium h-12 leading-[48px]",
                isIcon || shrinkOnMobile ? "min-w-12" : "px-[15px]",
                !isIcon && shrinkOnMobile && "tablet:w-fit tablet:px-[15px]"
            );
    }
};

export const getButtonVariantStyle = (variant: ButtonVariantT, color?: ButtonColorT): string => {
    switch (variant) {
        case "primary":
            return twMerge(
                "text-white bg-main enabled:hover:bg-[#2c2c2c] disabled:text-tertiaryOld disabled:bg-[#1414141f]",
                color === "error" && "bg-error enabled:hover:bg-error"
            );
        case "secondary":
            return twMerge(
                "text-main bg-[#1414140f] enabled:hover:bg-[#14141414] disabled:text-disabled disabled:bg-[#1414140a]",
                color === "error" && "bg-error"
            );
        case "text":
            return twMerge(
                "text-main enabled:hover:bg-[#1414140f] disabled:text-disabled",
                color === "error" && "text-error enabled:hover:bg-lightRedBg",
                color === "link" && "text-link"
            );
    }
};

/** Generates complete button styling based on props */
export const getButtonStyles = (
    {
        size = defaultButtonSize,
        variant = defaultButtonVariant,
        color = defaultButtonColor,
        isIcon,
        shrinkOnMobile
    }: {
        size?: ButtonSizeT;
        variant?: ButtonVariantT;
        color?: ButtonColorT;
        isIcon?: boolean;
        shrinkOnMobile?: boolean;
    } = {},
    ...classNames: ClassNameValue[]
): string =>
    twMerge(
        baseButtonStyles,
        getButtonSizeStyle(size, isIcon, shrinkOnMobile),
        getButtonVariantStyle(variant, color),
        ...classNames
    );

/* If you want to use an icon on the right use the children to pass text and icon and ignore the PhosphorIcon parameter */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        children,
        type = "button",
        size = defaultButtonSize,
        variant = defaultButtonVariant,
        color = defaultButtonColor,
        PhosphorIcon,
        iconProps,
        tooltip,
        tooltipMode,
        shrinkOnMobile,
        ...otherProps
    },
    ref
) {
    const hasLabel = !!children;
    const collapseToIcon = shrinkOnMobile && !!PhosphorIcon;

    const btn = (
        <button
            ref={ref}
            {...otherProps}
            type={type}
            className={getButtonStyles(
                {
                    size,
                    variant,
                    color,
                    isIcon: !hasLabel,
                    shrinkOnMobile: collapseToIcon
                },
                otherProps.className
            )}
        >
            {PhosphorIcon && <PhosphorIcon size={20} {...iconProps} />}
            {!collapseToIcon ? children : <span className="hidden tablet:inline">{children}</span>}
        </button>
    );

    return !tooltip ? (
        btn
    ) : (
        <TooltipWrapper content={tooltip} openOnClick={tooltipMode === "click"}>
            {btn}
        </TooltipWrapper>
    );
});
