import { ApiSelectOption } from "api/common/ApiSelectOption";
import { SingleSelect } from "components/common/v2/select";
import { MultiSelectOption } from "components/common/v2/select/types";
import { useModalManager } from "hooks/use-modal-manager";
import { useCurrencies } from "hooks/useCurrencies";
import React, { useMemo } from "react";

export const DefaultCurrencySelector = ({
    value,
    onChange
}: {
    value: string;
    onChange: (value: string) => void;
}) => {
    const { currencyOptionsList } = useCurrencies();
    const { openModal } = useModalManager();

    const options = useMemo(() => {
        return currencyOptionsList.reduce((acc: MultiSelectOption[], currency: ApiSelectOption) => {
            if (currency.value) {
                acc.push({
                    value: currency.value,
                    name: `${currency.label}${currency.slug ? ` (${currency.slug})` : ""}`
                });
            }
            return acc;
        }, []);
    }, [currencyOptionsList]);
    return (
        <>
            <label htmlFor="currency" className="block text-primary" data-testid="label-currency">
                Default currency for display of rates
            </label>
            <p className="text-secondary mb-4">
                Your selection here only affects the default display of currencies in the booking
                platform. Bookings are often made in a hotel&apos;s currency or a fixed currency
                like USD. Please refer to the information on the checkout page to understand how
                your client will be charged. If you&apos;d like to request an additional currency be
                added, please submit{" "}
                <span
                    className="underline cursor-pointer"
                    onClick={() => openModal("report-issue")}
                >
                    feedback
                </span>
            </p>
            <SingleSelect
                label="Default currency for display of rates"
                value={value}
                onChange={val => onChange(val)}
                options={options}
            />
        </>
    );
};
