import { UserProfile } from "api/user/profile";
import { Modal } from "components/common/v2/modal/Modal";
import { DefaultCurrencySelector } from "components/profileSettings/DefaultCurrencySelector";
import { useModalManager } from "hooks/use-modal-manager";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingIndicator } from "components/common/v2/LoadingIndicator";
import PortalAnalytics from "PortalAnalytics";
import { useRouter } from "next/router";
import { useSearchParams, usePathname } from "next/navigation";
import { ApiQueryParamsType } from "../types";
import { useGetUserProfile } from "hooks/useGetUserProfile";
import { useUpdateDefaultCurrency } from "hooks/useUpdateDefaultCurrency";

const useUpdateQueryParam = () => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = Object.fromEntries([...useSearchParams().entries()]);

    function updateQueryParam(param: keyof ApiQueryParamsType, newValue: any) {
        let query = {
            ...searchParams
        };

        query = {
            ...query,
            [param]: newValue
        };

        if ("modal" in query) {
            delete query["modal"];
        }

        router.push(
            {
                pathname,
                query
            },
            undefined,
            { shallow: true }
        );
    }
    return { updateQueryParam, router, pathname, searchParams };
};

export const DefaultCurrencyModal = () => {
    const { closeModal } = useModalManager();
    const { updateQueryParam, searchParams } = useUpdateQueryParam();
    const { data, isLoading, error: userProfileError, reValidateProfileData } = useGetUserProfile();

    const [value, setValue] = useState<string>(searchParams.currency ?? "");

    const { saveCurrency, isSaving, error } = useUpdateDefaultCurrency({
        onSuccessCallback: () => {
            PortalAnalytics.track("update_default_currency", {
                default_currency: value
            });

            updateQueryParam("currency", value);
            // refetch user profile so profile data is stale and has old currency until refresh/mount
            reValidateProfileData();
            closeModal(false);
        },
        onErrorCallback: e => {
            PortalAnalytics.track("update_default_currency__error", {
                default_currency: value,
                error: e.message
            });
        }
    });

    useEffect(() => {
        if (data && !isLoading) {
            const profile = new UserProfile(data);
            setValue(profile.default_currency);
        }

        if (userProfileError) {
            console.warn("Error fetching user profile", userProfileError);
        }
    }, [data]);

    const handleSave = () => {
        if (isSaving) return;

        saveCurrency({ data: { default_currency: value } });
    };

    const Loader = () => <LoadingIndicator className="bg-transparent shadow-none" />;

    const footerButtons = useCallback(() => {
        if (error) {
            return {
                primary: {
                    children: isSaving ? <Loader /> : "Try again",
                    onClick: handleSave
                }
            };
        }

        return {
            primary: {
                children: isSaving ? <Loader /> : "Save",
                "data-testid": "try-again",
                onClick: handleSave
            },
            secondary: {
                children: "Cancel",
                "data-testid": "cancel",
                onClick: () => closeModal()
            }
        };
    }, [error, isSaving, value]);

    return (
        <Modal
            key={data?.default_currency}
            open={!!searchParams.modal}
            onClose={closeModal}
            headerSize="large"
            title={error ? "" : "Adjust your default currency."}
            footerButtons={footerButtons()}
            data-testid="default-currency-modal"
        >
            <div className="pb-4">
                {error ? (
                    <div>
                        <p className="text-negative text-header mb-6">
                            An error occured while saving.
                        </p>

                        <p>
                            Please try agian. If you continue to experience issues, contact{" "}
                            <a
                                className="underline"
                                href="mailto:support@fora.travel"
                                target="_blank"
                                rel="noreferrer"
                            >
                                support@fora.travel
                            </a>
                        </p>
                    </div>
                ) : (
                    <DefaultCurrencySelector value={value} onChange={setValue} />
                )}
            </div>
        </Modal>
    );
};
