import { ComboboxInput } from "@headlessui/react";
import { CaretDown, MagnifyingGlass } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";
import { useMultiSelectContext, useSingleSelectContext } from "./select-context";
import { selectInputBaseStyles } from "./const";

export const SelectInput = ({
    open,
    mode = "single",
    onClick,
    readOnly = false,
    isMobile = false
}: {
    open: boolean;
    readOnly?: boolean;
    onClick?: () => void;
    isMobile?: boolean;
    mode?: "single" | "multi";
}) => {
    const context = mode === "multi" ? useMultiSelectContext : useSingleSelectContext;

    const placeholder = context().placeholder;
    const displayValue = context().displayValue;
    const setQuery = context().setQuery;

    return (
        <div className="w-full relative">
            <ComboboxInput
                data-testid="select-input"
                onClick={onClick}
                enterKeyHint="search"
                autoFocus={isMobile}
                className={selectInputBaseStyles}
                onChange={event => setQuery?.(event.target.value)}
                placeholder={isMobile ? "Search" : placeholder}
                readOnly={readOnly}
                type="search"
                autoComplete="off"
                displayValue={() => {
                    return displayValue ?? "";
                }}
            />

            {!isMobile ? (
                <CaretDown
                    size={15}
                    className={twMerge(
                        "absolute right-4 top-1/2 transform -translate-y-1/2",
                        open && "rotate-180"
                    )}
                />
            ) : (
                <MagnifyingGlass
                    size={15}
                    className={twMerge("absolute right-4 top-1/2 transform -translate-y-1/2")}
                />
            )}
        </div>
    );
};
