import React from "react";
import { SimpleModal } from "./SimpleModal";

export const TestModal = () => {
    return (
        <SimpleModal title="Test Modal">
            <div>TEST</div>
        </SimpleModal>
    );
};
