import { Modal } from "components/common/v2/modal/Modal";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";

export const CertificationQuizFailure = ({ closeModal }: { closeModal: () => void }) => {
    //TODO: Add Links to modal buttons
    const router = useRouter();
    return (
        <Modal
            title="Study time!"
            open={true}
            onClose={closeModal}
            footerButtons={{
                primary: {
                    onClick: () => router.push("/training/certified"),
                    children: "Return to Training"
                }
            }}
        >
            <div className="text-medium font-medium text-secondaryDark flex flex-col gap-4">
                <p>Unfortunately, your score wasn’t high enough to pass the Certified quiz.</p>
                <p>
                    We suggest you review the Certified curriculum here and take the quiz again. You
                    may take the quiz as many times as you need to achieve a passing score.
                </p>
                <p>
                    To earn certification, advisors must pass the Certified quiz and make their
                    first booking, which will be logged in Portal. Once complete, we issue
                    certification at the beginning of each month.
                </p>
            </div>
        </Modal>
    );
};
