import { createContext, useContext, useMemo, useState } from "react";
import {
    MultiSelectContextT,
    MultiSelectOption,
    MultiSelectProps,
    SingleSelectProps,
    SingleSelectContextT
} from "./types";
import useWindowDimensions from "hooks/useWindowDimensions";

const getDisplayValue = (value: string, options: MultiSelectOption[]) => {
    const found = options.find(option => {
        if (option.options) {
            return option.options.find(option => option.value === value);
        }

        return option.value === value;
    });

    const foundName = found?.options
        ? found.options.find(option => option.value === value)?.name
        : found?.name;

    return foundName ?? "";
};

const commonDefaultContextValues = {
    options: [],
    onChange: () => {},
    label: "",
    displayValue: "",
    setQuery: () => {},
    query: "",
    isMobile: false,
    placeholder: ""
};

const singleSelectContextDefaultValues = {
    ...commonDefaultContextValues,
    variant: "default" as const,
    showClearButton: false,
    onActionButtonClick: () => {},
    actionButtonLabel: "",
    value: ""
};

const SingleSelectContext = createContext<SingleSelectContextT>(singleSelectContextDefaultValues);

export function useSingleSelectContext() {
    return useContext<SingleSelectContextT>(SingleSelectContext);
}

export const SingleSelectContextProvider: React.FC<SingleSelectProps> = ({
    children,
    value,
    ...props
}) => {
    const { isMobile } = useWindowDimensions();
    const [query, setQuery] = useState("");
    const displayValue = useMemo(
        () => getDisplayValue(value, props.options),
        [value, props.options]
    );
    return (
        <SingleSelectContext.Provider
            value={{
                ...singleSelectContextDefaultValues,
                query,
                displayValue,
                setQuery,
                isMobile,
                value,
                ...props
            }}
        >
            {children}
        </SingleSelectContext.Provider>
    );
};

const MultiSelectContext = createContext<MultiSelectContextT>({
    ...commonDefaultContextValues,
    showClearButton: true,
    values: [],
    showSelected: true
});

export const useMultiSelectContext = () => useContext<MultiSelectContextT>(MultiSelectContext);

export const MultiSelectContextProvider: React.FC<MultiSelectProps> = ({
    children,
    onChange,
    values,
    showSelected = true,
    options,
    ...props
}) => {
    const { isMobile } = useWindowDimensions();
    const [query, setQuery] = useState("");

    const displayValue = useMemo(
        () =>
            values.length
                ? `${getDisplayValue(values[0], options)} ${
                      values.length > 1 ? `+ ${values.length - 1}` : ""
                  }`
                : "",
        [values, options]
    );

    return (
        <MultiSelectContext.Provider
            value={{
                options,
                onChange,
                values,
                displayValue: showSelected ? displayValue : "",
                setQuery,
                query,
                isMobile,
                ...props
            }}
        >
            {children}
        </MultiSelectContext.Provider>
    );
};
