import React from "react";
import { AddClientForm } from "components/form/forms/AddClientForm";
import { Modal } from "components/common/v2/modal/Modal";
import { useModalManager } from "hooks/use-modal-manager";

export const AddClientModal = (): JSX.Element => {
    const { closeModal } = useModalManager();
    return (
        <Modal title="Add a new client" open={true} onClose={closeModal}>
            <AddClientForm onDone={closeModal} />
        </Modal>
    );
};
