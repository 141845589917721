import React from "react";
import { RequestSupplierLoginForm } from "components/form/forms/RequestSupplierLoginForm";
import { useSearchParams } from "next/navigation";
import { Modal } from "components/common/v2/modal/Modal";
import { useModalManager } from "hooks/use-modal-manager";

export const RequestSupplierLoginModal = () => {
    const { closeModal } = useModalManager();
    const searchParams = useSearchParams();
    const supplier = searchParams?.get("supplier");
    const stage = searchParams?.get("stage");
    const defaultValues = {
        ...(supplier ? { supplier } : {}),
        ...(stage ? { stage } : {})
    };
    return (
        <Modal title="Request a Supplier Login" open={true} onClose={closeModal}>
            <RequestSupplierLoginForm onDone={closeModal} defaultValues={defaultValues} />
        </Modal>
    );
};
