import { Checkbox } from "../Checkbox";
import { ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { SelectOptionI, SelectOptionsProps } from "./types";
import { Check } from "@phosphor-icons/react";
import { selectOptionsBaseStyles, selectOptionsContainer } from "./const";
import { SingleSelectActionButton } from "./SingleSelectActionButton";
import React, { useMemo } from "react";

const SelectOption = ({
    isSingleSelect,
    option,
    isGrouped = false
}: {
    isSingleSelect?: boolean;
    isGrouped?: boolean;
    option: SelectOptionI;
}) => {
    return (
        <ComboboxOption
            value={option.value}
            data-testid={`option-${option.value}`}
            className={twMerge(
                selectOptionsBaseStyles,
                isSingleSelect && "justify-between",
                !isSingleSelect && isGrouped && "pl-8"
            )}
        >
            {isSingleSelect ? (
                <>
                    {option.name}
                    <Check className="invisible size-5 group-data-[selected]:visible" />
                </>
            ) : (
                ({ selected }: { selected: boolean }) => (
                    <>
                        <Checkbox
                            checked={selected}
                            data-testid={`option-checkbox-${option.value}`}
                        />
                        {option.name}
                    </>
                )
            )}
        </ComboboxOption>
    );
};

export function SelectOptions({
    clearSelection,
    options,
    isGroupSelected,
    onGroupSelect,
    actionButtonLabel,
    onActionButtonClick,
    closeOnSelect,
    showClearButton = true,
    isMobile = false,
    isSingleSelect = true,
    optionsHeight = 0
}: SelectOptionsProps) {
    const originalOptionsLength = useMemo(() => options.length, []);
    // 40px is the height of each option, input is 16px padding top + bottom
    const minOptionsListHeight = optionsHeight || originalOptionsLength * 40 + 16;
    // 7 is the max we want to show
    const maxOptionsListHeight = 7 * 40 + 16;

    if (!isMobile && options.length === 0) return null;

    return (
        <>
            <ComboboxOptions
                data-testid="select-options"
                static={isMobile}
                anchor={
                    !isMobile && {
                        to: "bottom",
                        gap: 4
                    }
                }
                className={twMerge(
                    selectOptionsContainer,
                    "w-[var(--input-width)]",
                    isMobile && `border-0 mt-1 `
                )}
                as="div"
            >
                {showClearButton && (
                    <ComboboxOption
                        data-testid="clear-selection-button"
                        aria-label="clear-selection-button"
                        value=""
                        onClick={clearSelection}
                        className={twMerge(selectOptionsBaseStyles, "text-secondary font-normal")}
                    >
                        - Clear selection -
                    </ComboboxOption>
                )}

                <div
                    className={twMerge(
                        isMobile && `min-h-[${minOptionsListHeight}px]`,
                        !isMobile && `max-h-[${maxOptionsListHeight}px]`
                    )}
                >
                    {options.map((option, index) => {
                        return option.options ? (
                            <React.Fragment key={index}>
                                <div
                                    className={selectOptionsBaseStyles}
                                    key={option.name + option.value}
                                    role="option"
                                    data-testid={`group-option-${option.value}`}
                                    onClick={() => {
                                        onGroupSelect?.(option);
                                    }}
                                >
                                    <Checkbox
                                        data-testid={`group-option-checkbox-${option.value}`}
                                        checked={isGroupSelected?.(option) ?? false}
                                    />
                                    {option.name}
                                </div>

                                {option.options?.map(option => (
                                    <SelectOption
                                        key={option.value}
                                        option={option}
                                        isSingleSelect={isSingleSelect}
                                        isGrouped
                                    />
                                ))}
                            </React.Fragment>
                        ) : (
                            <SelectOption
                                key={option.value}
                                option={option}
                                isSingleSelect={isSingleSelect}
                            />
                        );
                    })}
                </div>
                {actionButtonLabel && onActionButtonClick && (
                    <SingleSelectActionButton
                        isMobile={isMobile}
                        onClick={() => {
                            closeOnSelect?.();
                            onActionButtonClick();
                        }}
                        label={actionButtonLabel}
                        className="mt-2"
                    />
                )}
            </ComboboxOptions>
        </>
    );
}
