import { Combobox, ListboxButton, Listbox, ListboxOption, ListboxOptions } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { Check, CaretDown } from "@phosphor-icons/react";
import { SelectInput } from "./SelectInput";
import { SelectOptions } from "./SelectOptions";
import { useSingleSelectContext } from "./select-context";
import { selectOptionsBaseStyles, selectInputBaseStyles, selectOptionsContainer } from "./const";
import { SingleSelectActionButton } from "./SingleSelectActionButton";
import { filterByName } from "./MultiSelectDropdown";

interface Props {
    /** for use on mobile to close the select on the modal */
    closeOnSelect?: () => void;
}
export const SingleSelectButton = ({
    open,
    onClick,
    isHidden = false
}: {
    open: boolean;
    isHidden?: boolean;
    onClick?: () => void;
}) => {
    const { displayValue, placeholder } = useSingleSelectContext();

    return (
        <ListboxButton
            // for some reason ListboxButton accepts a placeholder but doesn't render it :/ putting it here for tests
            placeholder={placeholder}
            className={twMerge(
                selectInputBaseStyles,
                "h-12",
                isHidden && "invisible h-0 p-0",
                placeholder && !displayValue && "text-tertiary"
            )}
            onClick={() => onClick?.()}
        >
            {displayValue || placeholder}

            <CaretDown
                size={15}
                className={twMerge(
                    "absolute right-4 top-1/2 transform -translate-y-1/2",
                    open && "rotate-180"
                )}
            />
        </ListboxButton>
    );
};

export function SingleSelectDefault({ closeOnSelect }: Props) {
    const {
        value,
        showClearButton,
        actionButtonLabel,
        onActionButtonClick,
        options,
        isMobile,
        onChange
    } = useSingleSelectContext();

    return (
        <Listbox
            key={value}
            value={value}
            onChange={val => {
                onChange(val);
                closeOnSelect?.();
            }}
        >
            {({ open }) => (
                <>
                    <SingleSelectButton open={open} isHidden={isMobile} />

                    <ListboxOptions
                        static={isMobile}
                        transition
                        anchor={
                            !isMobile && {
                                to: "bottom",
                                gap: 4
                            }
                        }
                        className={twMerge(
                            selectOptionsContainer,
                            `w-[var(--button-width)]`,
                            isMobile && `border-0 p-0 -mt-6`
                        )}
                    >
                        {showClearButton && value && (
                            <ListboxOption
                                key="clear"
                                value=""
                                className={twMerge(
                                    selectOptionsBaseStyles,
                                    "text-secondary font-normal"
                                )}
                            >
                                - Clear selection -
                            </ListboxOption>
                        )}
                        {options.map(option => (
                            <ListboxOption
                                onClick={() => closeOnSelect?.()}
                                key={option.value}
                                value={option.value}
                                className={twMerge(selectOptionsBaseStyles, "justify-between")}
                            >
                                {option.name}
                                <Check className="invisible size-5 group-data-[selected]:visible" />
                            </ListboxOption>
                        ))}
                        {actionButtonLabel && onActionButtonClick && (
                            <SingleSelectActionButton
                                isMobile={isMobile}
                                onClick={() => {
                                    closeOnSelect?.();
                                    onActionButtonClick();
                                }}
                                label={actionButtonLabel}
                            />
                        )}
                    </ListboxOptions>
                </>
            )}
        </Listbox>
    );
}
export function SingleSelectContainer({ closeOnSelect }: Props) {
    const {
        value = "",
        showClearButton = false,
        actionButtonLabel,
        onActionButtonClick,
        options,
        onChange,
        query,
        setQuery,
        isMobile,
        variant
    } = useSingleSelectContext();

    const filteredOptions =
        query === ""
            ? options
            : options.filter(option => {
                  return filterByName(option.name, query);
              });

    if (variant === "search") {
        return (
            <Combobox
                immediate
                value={value}
                onChange={val => {
                    // so the value doesnt get cleared when a user clears the search input
                    if (query === "") {
                        onChange(val ?? "");
                    } else {
                        val && onChange(val ?? "");
                    }
                    // if null dont close
                    val && closeOnSelect?.();
                }}
                onClose={() => setQuery("")}
            >
                {({ open }) => (
                    <>
                        <SelectInput open={open} isMobile={isMobile} />

                        <SelectOptions
                            options={filteredOptions}
                            clearSelection={() => onChange("")}
                            showClearButton={showClearButton && !!value}
                            isMobile={isMobile}
                            isSingleSelect
                            actionButtonLabel={actionButtonLabel}
                            onActionButtonClick={onActionButtonClick}
                            closeOnSelect={closeOnSelect}
                        />
                    </>
                )}
            </Combobox>
        );
    }

    return <SingleSelectDefault closeOnSelect={closeOnSelect} />;
}
