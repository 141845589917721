import React, { useContext } from "react";
import { useSession } from "next-auth/react";
import { Dialog } from "@headlessui/react";

import { UserProfile } from "api/user/profile";
import { StoreContext } from "store/index";
import { classNames } from "utils/index";

type ModalViewType = "empty" | "basic";
type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean, goBack?: boolean) => void;
    children: React.ReactNode;
    type?: ModalViewType;
    containerClassName?: string;
    dialogClassname?: string;
    handleCloseCallback?: (value: boolean) => void;
    unmount?: boolean;
    noPadding?: boolean;
};

const ModalCore = ({
    isOpen,
    setIsOpen,
    children,
    type,
    containerClassName,
    dialogClassname = "",
    handleCloseCallback,
    unmount = false,
    noPadding = false
}: Props) => {
    const { errors } = useContext(StoreContext);
    const { data: session } = useSession();

    const isImpersonating = session?.isImpersonating;

    return (
        <Dialog
            unmount={unmount}
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
                handleCloseCallback?.(false);
            }}
            className={classNames("relative z-50", dialogClassname)}
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="dialog-backdrop fixed inset-0 bg-charcoal/70" aria-hidden="true" />

            {/* Full-screen scrollable container */}
            <div
                className={classNames(
                    !!isImpersonating && "lg:mt-[65px]",
                    "dialog-scroll-container fixed inset-0 z-10 overflow-y-auto"
                )}
            >
                {/* Container to center the panel */}
                <div
                    className={`dialog-centered-container flex min-h-full items-center justify-center ${
                        noPadding ? "" : "p-6 [@media(max-width:375px)]:p-4 sm:p-0"
                    }`}
                >
                    {/* Full-screen container to center the panel */}

                    {/*  Max width class for modal with form; class 'self-center' is for small modals */}
                    {/*  Max width class for modal with form */}

                    {/* The actual dialog panel  */}
                    <Dialog.Panel className={`my-4 ${containerClassName}`}>{children}</Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalCore;
