import { Modal } from "../modal/Modal";
import { SelectMobileProps } from "./types";

export const SelectMobile: React.FC<SelectMobileProps> = ({
    footerButtons,
    selectInput,
    closeModal,
    children,
    showModal,
    label
}) => {
    return (
        <>
            {selectInput}

            <Modal
                open={showModal}
                title={label}
                onClose={closeModal}
                footerButtons={footerButtons}
            >
                {children}
            </Modal>
        </>
    );
};
