import { useModalManager } from "hooks/use-modal-manager";
import React, { ReactNode } from "react";
import { Modal, ModalProps } from "./Modal";

interface Props extends Omit<ModalProps, "children"> {
    children:
        | ReactNode
        | string
        | ((modalActions: {
              openModal: (modalId: string) => void;
              closeModal: () => void;
          }) => ReactNode);
}
// This needs to be used in conjunction withy ModalManager, if you want your own implementation to handle open and close use Modal instead
export const SimpleModal = ({ children, ...otherProps }: Props) => {
    const { openModal, closeModal } = useModalManager();
    return (
        <Modal {...otherProps} onClose={closeModal}>
            {children &&
                (typeof children === "function" ? children({ openModal, closeModal }) : children)}
        </Modal>
    );
};
