import React, { useState } from "react";
import { useSingleSelectContext } from "./select-context";
import { Combobox } from "@headlessui/react";
import { SelectInput } from "./SelectInput";
import { SingleSelectContainer } from "./SingleSelectContainer";
import { SelectMobile } from "./SelectMobile";
import { selectInputBaseStyles } from "./const";
import { CaretDown } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";

const SingleSelectMobileInput = ({ onClick }: { onClick: () => void }) => {
    const { variant, displayValue, value, placeholder } = useSingleSelectContext();
    if (variant === "search") {
        return (
            <Combobox value={value}>
                <SelectInput onClick={onClick} open={false} readOnly />
            </Combobox>
        );
    }

    return (
        /** had to use a div here instead of ListboxButton since Listbox options become buggy on select
         * (have to double click to select) when there is more than one button on the screen*/
        <div
            data-testid="single-select-input"
            onClick={onClick}
            className={twMerge(
                selectInputBaseStyles,
                "h-12",
                placeholder && !displayValue && "text-tertiary"
            )}
            role="button"
        >
            {displayValue || placeholder}
            <CaretDown
                size={15}
                className={"absolute right-4 top-1/2 transform -translate-y-1/2"}
            />
        </div>
    );
};

export const SingleSelectMobile = () => {
    const { label } = useSingleSelectContext();
    const [showModal, setShowModal] = useState(false);
    return (
        <SelectMobile
            showModal={showModal}
            label={label}
            closeModal={() => setShowModal(false)}
            selectInput={<SingleSelectMobileInput onClick={() => setShowModal(true)} />}
        >
            <SingleSelectContainer closeOnSelect={() => setShowModal(false)} />
        </SelectMobile>
    );
};
